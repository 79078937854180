
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "sample-code",
  components: {
    CodeHighlighter,
  },
});
