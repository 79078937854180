<template>
  <div class="row">
    <!-- Successful Response -->
    <div class="col-xxl-6">
      <div class="card card-flush mt-4">
        <div class="card-body">
          <h3>{{ $t("ApiManual.SuccessResponse") }}</h3>
          <div class="mt-4 mb-9">
            <div class="alert alert-success d-flex align-items-center p-5 mt-5">
              <span class="svg-icon svg-icon-2hx svg-icon-success me-4">
                <inline-svg src="/media/icons/duotune/general/gen048.svg" />
              </span>
              <div class="d-flex flex-column">
                <span>
                  Response Status: 200; Content-Type: application/json;
                </span>
              </div>
            </div>
            <CodeHighlighter lang="json" :key="1">
              {{ success }}
            </CodeHighlighter>
          </div>
        </div>
      </div>
    </div>
    <!-- Error Response -->
    <div class="col-xxl-6">
      <div class="card card-flush mt-4">
        <div class="card-body">
          <h3>{{ $t("ApiManual.ErrorResponse") }}</h3>
          <div class="mt-4">
            <div class="alert alert-danger d-flex align-items-center p-5 mt-5">
              <span class="svg-icon svg-icon-2hx svg-icon-danger me-4">
                <inline-svg src="/media/icons/duotune/general/gen050.svg" />
              </span>
              <div class="d-flex flex-column">
                <span>
                  Response Status: 4XX / 5XX; Content-Type: application/json;
                </span>
              </div>
            </div>
            <CodeHighlighter lang="json" :key="1">
              {{ errorComment }}
              {{ error }}
            </CodeHighlighter>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "callback-response",
  components: {
    CodeHighlighter,
  },
  props: {
    success: { type: Object, required: true },
    error: { type: Object, required: true },
    errorComment: { type: String, required: true },
  },
});
</script>
