<template>
  <!-- Request -->
  <div class="card card-flush mt-4">
    <div class="card-body">
      <h3>REQUEST</h3>
      <div class="mt-4">
        <!-- URL -->
        <div class="input-group mb-3">
          <span class="input-group-text">{{
            "POST" + $t("ApiManual.PostAddress")
          }}</span>
          <input
            type="text"
            class="form-control"
            :disabled="true"
            :value="url"
          />
        </div>
        <!-- Header -->
        <label for="basic-url" class="form-label">Request Header</label>
        <div class="input-group mb-3">
          <span class="input-group-text">AGENTCODE</span>
          <input
            type="text"
            class="form-control"
            :disabled="true"
            :value="$store.state.AuthModule.user.agent"
          />
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text">AGENTID</span>
          <input
            type="text"
            class="form-control"
            :disabled="true"
            :value="$store.state.AuthModule.user.id"
          />
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text">SNOWSEC</span>
          <input
            type="text"
            class="form-control"
            :disabled="true"
            :value="$store.state.AuthModule.user.snowsec"
          />
        </div>
        <!-- Body -->
        <label for="basic-url" class="form-label"
          >Request Body (application/json)</label
        >
        <!-- Deposit Request -->
        <div v-if="page === 'DEPOSIT'">
          <!-- Snow Member ID -->
          <div class="row">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">member_id</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="launch.member_id"
                  placeholder="9fd4acc3-fa2c-4fc4-a530-fa517e8cdd6d"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.SnowMemberIDNotice") }}
              </label>
            </div>
          </div>
          <!-- Snow Amount -->
          <div class="row">
            <div class="col-xxl-6">
              <div class="input-group mb-3">
                <span class="input-group-text">amount</span>
                <input
                  type="number"
                  class="form-control"
                  v-model="launch.amount"
                  placeholder="1000"
                  required
                />
              </div>
            </div>
            <div class="col-xxl-6">
              <label class="text-danger mt-3">
                {{ $t("ApiManual.AmountNotice") }}
              </label>
            </div>
          </div>
        </div>
        <!-- Snow Member ID -->
        <div class="row">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">member_id</span>
              <input
                type="text"
                class="form-control"
                v-model="launch.member_id"
                placeholder="9fd4acc3-fa2c-4fc4-a530-fa517e8cdd6d"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.SnowMemberIDNotice") }}
            </label>
          </div>
        </div>
        <!-- Txn Id -->
        <div class="row" v-if="page !== 'BALANCE'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">txn_id</span>
              <input
                type="text"
                class="form-control"
                v-model="launch.txn_id"
                placeholder="88944aaf-0a02-4776-a151-326538051926"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.DebitTxnID") }}
            </label>
          </div>
        </div>
        <!-- Game code -->
        <div class="row" v-if="page === 'DEBIT' || page === 'CREDIT'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">game_code</span>
              <input
                type="text"
                class="form-control"
                v-model="launch.game_code"
                placeholder="evolution"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.GameCode") }}
            </label>
          </div>
        </div>
        <!-- Game type -->
        <div class="row" v-if="page === 'DEBIT' || page === 'CREDIT'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">game_type</span>
              <input
                type="text"
                class="form-control"
                v-model="launch.game_type"
                placeholder="LIVE"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.GameType") }}
            </label>
          </div>
        </div>
        <!-- Roomno -->
        <div class="row" v-if="page === 'DEBIT'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">roomno</span>
              <input
                type="text"
                class="form-control"
                v-model="launch.roomno"
                placeholder="Speed Baccarat A"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.Roomno") }}
            </label>
          </div>
        </div>
        <!-- Roomorder -->
        <div class="row" v-if="page === 'DEBIT'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">roomorder</span>
              <input
                type="text"
                class="form-control"
                v-model="launch.roomorder"
                placeholder="123456"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.Roomorder") }}
            </label>
          </div>
        </div>
        <!-- Betamount -->
        <div class="row" v-if="page === 'DEBIT'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">betamount</span>
              <input
                type="number"
                class="form-control"
                v-model="launch.betamount"
                placeholder="1000"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("BetSummary.BetAmount") }}
            </label>
          </div>
        </div>
        <!-- Winamount -->
        <div class="row" v-if="page === 'CREDIT'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">winamount</span>
              <input
                type="number"
                class="form-control"
                v-model="launch.winamount"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("BetSummary.WinAmount") }}
            </label>
          </div>
        </div>
        <!-- Rollback mount -->
        <div class="row" v-if="page === 'ROLLBACK'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">amount</span>
              <input
                type="number"
                class="form-control"
                v-model="launch.amount"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.RollbackAmount") }}
            </label>
          </div>
        </div>
        <!-- History ID -->
        <div class="row" v-if="page === 'CREDIT'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">history_id</span>
              <input
                type="text"
                class="form-control"
                v-model="launch.history_id"
                placeholder="111111"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.HistoryId") }}
            </label>
          </div>
        </div>
        <!-- Rollback Reason-->
        <div class="row" v-if="page === 'ROLLBACK'">
          <div class="col-xxl-6">
            <div class="input-group mb-3">
              <span class="input-group-text">reason</span>
              <input
                type="text"
                class="form-control"
                v-model="launch.reason"
                required
              />
            </div>
          </div>
          <div class="col-xxl-6">
            <label class="text-danger mt-3">
              {{ $t("ApiManual.RollbackReason") }}
            </label>
          </div>
        </div>
      </div>
      <!-- Submit -->
      <div class="mt-4 d-flex justify-content-end">
        <button class="btn btn-primary" @click="sendRequest()">
          <i class="fas fa-paper-plane fs-4 me-2"></i>
          {{ $t("ApiManual.Submit") }}
        </button>
      </div>
    </div>
  </div>
  <!-- Response -->
  <div class="card card-flush mt-4">
    <div class="card-body">
      <h3>RESPONSE</h3>
      <div class="mt-4">
        {{ responseStatus }}
        <CodeHighlighter lang="json" :key="new Date().getTime()">
          {{ response }}
        </CodeHighlighter>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "LiveCallbackTester",
  components: {
    CodeHighlighter,
  },
  props: {
    url: { type: String, required: true },
    page: { type: String, required: true },
  },
  setup(props) {
    let launch = reactive({
      member_id: "",
    });

    if (props.page === "DEBIT") {
      launch = reactive({
        member_id: "",
        txn_id: "",
        game_code: "",
        game_type: "",
        roomno: "",
        roomorder: "",
        betamount: 1000,
      });
    } else if (props.page === "CREDIT") {
      launch = reactive({
        member_id: "",
        txn_id: "",
        game_code: "",
        game_type: "",
        winamount: 1000,
        history_id: "",
      });
    } else if (props.page === "ROLLBACK") {
      launch = reactive({
        member_id: "",
        amount: 1000,
        reason: "REFUND",
      });
    }

    const responseStatus = ref("");
    const response = ref({});

    const sendRequest = async () => {
      let apiURL = props.url;
      await ApiService.post("/agent/callback-test", {
        apiURL,
        request: launch,
        method: props.method,
      })
        .then((res) => {
          responseStatus.value = res.status;
          response.value = res.data.data;
        })
        .catch((e) => {
          responseStatus.value = e.response.status;
          response.value = e.response.data.data;
        });
    };
    return {
      launch,
      sendRequest,
      response,
      responseStatus,
    };
  },
});
</script>
