
import { defineComponent } from "vue";
export default defineComponent({
  name: "api-header",
  props: {
    method: { type: String, required: true },
    url: { type: String, required: true },
    type: { type: String, required: false },
    noaddress: { type: Boolean, required: false },
  },
});
